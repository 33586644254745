@font-face {
  font-family: 'Jenthill';
  src: local("Jenthill"),
  url("./fonts/Jenthill.ttf") format("truetype");
}
@font-face {
  font-family: 'Cormorant Garamond';
  src: local("Cormorant Garamond"),
  url("./fonts/CormorantGaramond-Regular.ttf") format("truetype");
}
body {
  font-family: "Cormorant Garamond"
}
.poetry-editor-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

#editable {
  width: 80%;
  min-height: 200px;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.5;
  overflow-y: auto;
}

.rhymes {
  width: 80%;
}

.rhymes ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.rhymes li {
  padding: 5px;
}

h3 {
  font-family: 'Jenthill';
  font-weight: normal;
  letter-spacing: 0.75px;
  text-transform: lowercase;
}

[contenteditable=true]:empty:before{
  content: attr(placeholder);
  pointer-events: none;
  letter-spacing: 0.75px;
  color: grey;
  font-family: 'Jenthill';
  display: block; /* For Firefox */
}

* {
  -webkit-user-select: none;
  user-select: none;
}

input,
textarea,
[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}
